export const LANG_REGEX: RegExp = /\/([\w]{2})\//g;

const SUPPORTED_LANGS = ["en", "fr", "es", "it"];
const DEFAULT_LANG = SUPPORTED_LANGS[0];

export const hasLanguageInPathname = () => {
  const result = LANG_REGEX.test(location.pathname);
  LANG_REGEX.lastIndex = 0;
  return result;
}

export const urlLang = () => {
  const hasLanguage = hasLanguageInPathname();
  if (hasLanguage) {
    const execRegex: RegExpExecArray = LANG_REGEX.exec(location.pathname);
    LANG_REGEX.lastIndex = 0;
    return execRegex.length > 0 ? execRegex[0] : `/${DEFAULT_LANG}/`;
  }
  return `/${DEFAULT_LANG}/`;
};

export const getLocale = () => {
  const localeWithSlashes = urlLang();
  const shouldDefault = !localeWithSlashes || (localeWithSlashes && localeWithSlashes.length < 4);
  if(shouldDefault) {
    return DEFAULT_LANG.slice(1,3);
  }
  return localeWithSlashes.slice(1,3);
}

// Analyzes URL to detect if and where the language is inserted and will fix common bad formats.
// This function calls itself recursively so it can fix several concurrent bad formats at once.
export const addLang = () => {
  const hasLanguage = hasLanguageInPathname();
  const lang = getLocale();

  // If URL has no language, insert it
  if (!hasLanguage) {
    let lang = DEFAULT_LANG;
    SUPPORTED_LANGS.forEach((value, index, ) => {
      navigator.language.includes(value) ? lang = SUPPORTED_LANGS[index] : null;
    });
    const pathName = location.pathname;
    window.history.replaceState(null, "", `${location.origin}/${lang}${pathName}`);
    return addLang();
  }
  
  // If URL ends like /en with no final /, fix it.
  const itMissesFinalSlash = !location.href.endsWith('/');
  if(itMissesFinalSlash) {
    window.history.replaceState(null, "", `${location.href}/`);
    return addLang();
  }

  // If URL has language but not right after the domain, but in the middle of the URL, fix it.
  const whereLangShouldSit = location.href.split("/")[3] // 0: https,1: "/",2: domain(ie dapp.brickken.com),3: should be language;
  const shouldFixUrl = lang != whereLangShouldSit;
  if(shouldFixUrl) {
    const restOfUrl = location.pathname.split(`${lang}/`)
    const newUrl = `${location.origin}/${lang}${restOfUrl[0]}${restOfUrl[1]}`
    window.history.replaceState(null, "", newUrl);
    return addLang();
  }

  // If URL contains "//" at the beginning or end of pathname, fix it. 
  // In the middle they are automatically ignored.
  const startsWith = location.pathname.startsWith('//');
  const endsWith = location.pathname.endsWith('//');

  if(startsWith) {
    const newUrl = `${location.origin}${location.pathname.split('//')[1]}`;
    window.history.replaceState(null, "", newUrl);
    return addLang();
  }
  
  if(endsWith) {
    const newUrl = `${location.origin}${location.pathname.split('//')[0]}/`;
    console.log(newUrl);
    window.history.replaceState(null, "", newUrl);
    return addLang();
  }
};